<template>
  <div>
    <CSpinner color="primary" class="float-right spinner" v-if="isUpdating" />

    <CDataTable
      :loading="isLoading"
      :items="items"
      :fields="fields"
      :noItemsView="{
        noResults: $t('admin.no_results'),
        noItems: $t('admin.no_items')
      }"
      :itemsPerPage="20"
      border
      striped
      hover
      pagination
    >
      <template #show_actions="{item, index}">
        <td class="py-2 text-center" :style="getBorderStyle(item)">
          <CButton
            color="primary"
            square
            size="sm"
            class="mr-1"
            @click="updateStatus(item.id, 'confirmation')"
          >
            <CIcon name="cilSend" />
          </CButton>

          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            class="mr-1"
            @click="toggleManagerComment(index)"
          >
            <CIcon name="cilEnvelopeClosed" />
          </CButton>

          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            class="mr-1"
            @click="openIntervalsModal(item.order_id)"
          >
            <CIcon name="cilClock" />
          </CButton>

          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            class="mr-1"
            @click="openGroupModal(item.id)"
          >
            <CIcon name="cilLibraryAdd" />
          </CButton>
        </td>
      </template>

      <template #order_id="{item}">
        <td class="py-2">
          <OrderIdComposition :item="item" />
        </td>
      </template>

      <template #details="{item}">
        <CCollapse :show="Boolean(item._toggled)">
          <CCardBody>
            <CCol sm="4">
              <CTextarea
                v-model="item.manager_comment"
                @input="updateManagerComment(item.id, $event)"
              />
            </CCol>
          </CCardBody>
        </CCollapse>
      </template>

      <template #show_user="{item}">
        <td class="py-2">
          {{ item.user.full_name }}
        </td>
      </template>

      <template #show_address="{item}">
        <td class="py-2">
          {{ item.address.short }}
        </td>
      </template>

      <template #sub_total_quantity="{item}">
        <td class="py-2 text-center">
          {{ item.sub_total }}&euro; / {{ item.items.length }}
        </td>
      </template>
    </CDataTable>

    <ChangeOrderInterval
      :order="activeOrderId"
      @closed="closeIntervalsModal"
      @updated="getData"
    />

    <AddSubOrdersToGroup
      :subOrder="activeSubOrderId"
      :subOrders="items"
      @closed="closeGroupModal"
      @updated="getData"
    />

    <CModal
      :title="intervals_show"
      color="primary"
      :show="showInterval"
      @update:show="close"
    >
      <template #footer>
        <h5 class="modal-title">
          <CButton
            color="primary"
            square
            class="float-right"
            @click="closeSendConfirmation"
          >
            No
          </CButton>
        </h5>
        <h5 class="modal-title">
          <CButton
            color="primary"
            square
            class="float-right"
            @click="sendConfirmation"
          >
            Yes
          </CButton>
        </h5>
      </template>

      <div class="modal-body">
        <slot name="body"> Send all orders?</slot>
      </div>
    </CModal>
  </div>
</template>

<script>
import { Howl } from "howler";
import debounce from "lodash.debounce";
import ChangeOrderInterval from "@/components/management/ChangeOrderInterval";
import AddSubOrdersToGroup from "@/components/manager/AddSubOrdersToGroup";
import OrderIdComposition from "@/components/manager/OrderIdComposition";

export default {
  components: {
    ChangeOrderInterval,
    AddSubOrdersToGroup,
    OrderIdComposition
  },

  data() {
    return {
      isLoading: true,
      showInterval: false,
      intervals_show: "Intervals 19:00-19:30",
      intervals_no: 0,
      isUpdating: false,
      activeOrderId: null,
      activeSubOrderId: null,
      items: [],
      items_in_interval: [],
      intervals: [],
      fields: [
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 190px"
        },
        {
          key: "order_id",
          label: this.$t("manager.orders.order_id"),
          _style: "width: 100px"
        },
        {
          key: "restaurant",
          label: this.$t("manager.orders.restaurant")
        },
        {
          key: "show_user",
          label: this.$t("manager.orders.client")
        },
        {
          key: "delivery_time_simple",
          label: this.$t("manager.orders.delivery_time")
        },
        {
          key: "sub_total_quantity",
          label: this.$t("manager.orders.new.sub_total_quantity")
        }
      ],
      sound: null,
      manager_comment: ""
    };
  },

  created() {
    this.sound = new Howl({
      src: ["/sounds/cash.webm", "/sounds/cash.mp3"]
    });
  },

  watch: {
    items(new_items, old_items) {
      if (new_items.length > 0 && old_items.length > 0) {
        if (new_items[0].id > old_items[0].id) {
          this.sound.play();
        }
      }
    }
  },

  methods: {
    getData() {
      return this.$http
        .get(`/api/manager/sub-orders`, { params: { status: "new" } })
        .then(response => {
          this.items = response.data.data;

          let superthis = this.intervals_no;
          this.items_in_interval = this.items.filter(value => {
            let spl = value.delivery_time_simple.split("-")[1].split(":");
            let date = new Date();
            let dateCurrent = new Date();
            date.setHours(spl[0]);
            date.setMinutes(spl[1]);
            date.setSeconds(0);
            console.log(date);
            date.setMinutes(date.getMinutes() - 60 + superthis);
            console.log(date);
            if (dateCurrent.getTime() >= date.getTime()) return value.id;
          });

          if (this.items_in_interval.length > 0) {
            this.showInterval = true;
            let dateCurrent = new Date();
            let dateCurrent2 = new Date();
            dateCurrent.setMinutes(dateCurrent.getMinutes() + 30);
            dateCurrent2.setMinutes(dateCurrent2.getMinutes() + 60);
            this.intervals_show =
              "Intervals " +
              dateCurrent.getHours() +
              ":" +
              ("0" + dateCurrent.getMinutes()).slice(-2) +
              "-" +
              dateCurrent2.getHours() +
              ":" +
              ("0" + dateCurrent2.getMinutes()).slice(-2);
            this.intervals_no = 0;
            this.sound.play();
          }

          this.intervals = response.data.meta.intervals;
          this.isLoading = false;
        });
    },
    async updateStatus(id, status) {
      this.isLoading = true;

      await this.$http.patch(`/api/manager/sub-orders/${id}`, { status });
      await this.getData();

      this.isLoading = false;
    },
    sendConfirmation() {
      this.isLoading = true;
      this.items_in_interval.forEach(sub_order => {
        this.$http.patch(`/api/manager/sub-orders/${sub_order.id}`, {
          status: "confirmation"
        });
      });
      this.isLoading = false;
      this.close();
    },
    closeSendConfirmation() {
      this.close();
      this.intervals_no += 15;
      this.$emit("updated");
    },
    close() {
      this.showInterval = false;
      this.$emit("closed");
    },
    openIntervalsModal(id) {
      this.activeOrderId = id;
    },
    closeIntervalsModal() {
      this.activeOrderId = null;
    },
    openGroupModal(id) {
      this.activeSubOrderId = id;
    },
    closeGroupModal() {
      this.activeSubOrderId = null;
    },
    toggleManagerComment(index) {
      const sub_order = this.items[index];

      this.$set(sub_order, "_toggled", !sub_order._toggled);
    },
    updateManagerComment: debounce(function(id, manager_comment) {
      this.isUpdating = true;

      this.$http
        .patch(`/api/manager/sub-orders/${id}`, { manager_comment })
        .then(() => (this.isUpdating = false));
    }, 500),
    getBorderStyle(sub_order) {
      const interval = this.intervals.find(
        interval =>
          interval.to === sub_order.delivery_time_simple.split("-")[1] &&
          interval.is_pickup === sub_order.is_pickup
      );
      const colour = interval ? interval.colour : "#ffffff";

      return { borderLeftColor: colour, borderLeftWidth: "8px" };
    }
  },

  metaInfo() {
    return {
      title: this.$t("manager.orders.new.title")
    };
  }
};
</script>

<style scoped>
.spinner {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
</style>
