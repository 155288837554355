<template>
  <CModal
    title="Group orders"
    color="primary"
    :show.sync="subOrder"
    @update:show="close"
  >
    <template #footer>
      <h5 class="modal-title">
        <CButton color="primary" square class="float-right" @click="update">
          {{ $t("buttons.save") }}
        </CButton>
      </h5>
    </template>

    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
      {{ errors.message }}
      <ul>
        <li v-for="(error, field) in errors.errors" :key="field">
          {{ error[0] }}
        </li>
      </ul>
    </CAlert>

    <CRow>
      <CCol sm="12">
        <multiselect
          v-model="selectedSubOrders"
          :options="filteredSubOrders"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select orders"
          label="order_id"
          track-by="id"
        />
      </CCol>
    </CRow>
  </CModal>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  props: ["subOrder", "subOrders"],

  components: {
    Multiselect
  },

  data() {
    return {
      isLoading: false,
      errors: [],
      selectedSubOrders: []
    };
  },

  watch: {
    subOrder: function(id) {
      if (id !== null) {
        this.selectedSubOrders = this.subOrders.filter(subOrder =>
          this.subOrderItem.grouped_with.includes(subOrder.id)
        );
      }
    }
  },

  computed: {
    subOrderItem() {
      return this.subOrders.find(subOrder => subOrder.id === this.subOrder);
    },
    filteredSubOrders() {
      if (this.subOrderItem === undefined) {
        return [];
      }

      return this.subOrders.filter(
        subOrder =>
          subOrder.restaurant_id === this.subOrderItem.restaurant_id &&
          subOrder.id !== this.subOrderItem.id
      );
    }
  },

  methods: {
    update() {
      this.isLoading = true;
      this.errors = [];
      this.$http
        .put(`/api/manager/sub-orders/${this.subOrder}`, {
          grouped_with: this.selectedSubOrders.map(subOrder => subOrder.id)
        })
        .then(() => {
          this.close();
          this.$emit("updated");
        })
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    },
    close() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$emit("closed");
    }
  }
};
</script>
