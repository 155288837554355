<template>
  <div>
    {{ item.user.comment !== null ? "🔥 " : "" }}
    <router-link
      :to="{ name: 'admin.orders.show', params: { order: item.order_id } }"
      target="_blank"
    >
      {{ item.order_id }}
    </router-link>
    {{
      item.grouped_with_orders.length > 0
        ? " (+" + item.grouped_with_orders.join(", +") + ")"
        : ""
    }}
  </div>
</template>

<script>
export default {
  props: ["item"]
};
</script>
