<template>
  <CDataTable
    :loading="isLoading"
    :items="items"
    :fields="fields"
    :noItemsView="{
      noResults: $t('admin.no_results'),
      noItems: $t('admin.no_items')
    }"
    border
    striped
    hover
    pagination
  >
    <template #show_user="{item}">
      <td class="py-2">
        {{ item.user.full_name }}
      </td>
    </template>

    <template #show_courier="{item}">
      <td class="py-2">
        {{ item.courier ? item.courier.name : "—" }}
      </td>
    </template>

    <template #show_address="{item}">
      <td class="py-2">
        {{ item.address.short }}
      </td>
    </template>
  </CDataTable>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      items: [],
      fields: [
        {
          key: "order_id",
          label: this.$t("manager.orders.order_id"),
          _style: "width: 100px"
        },
        {
          key: "restaurant",
          label: this.$t("manager.orders.restaurant")
        },
        {
          key: "show_user",
          label: this.$t("manager.orders.client")
        },
        {
          key: "show_courier",
          label: this.$t("manager.orders.in_delivery.courier")
        },
        {
          key: "show_address",
          label: this.$t("manager.orders.address")
        },
        {
          key: "picked_up_at",
          label: this.$t("manager.orders.in_delivery.picked_up_at")
        },
        {
          key: "delivery_time",
          label: this.$t("manager.orders.delivery_time")
        }
      ]
    };
  },

  methods: {
    getData() {
      return this.$http
        .get(`/api/manager/sub-orders`, { params: { status: "in_delivery" } })
        .then(response => {
          this.items = response.data.data;
          this.isLoading = false;
        });
    },
    async updateStatus(id, status) {
      this.isLoading = true;

      await this.$http.patch(`/api/manager/sub-orders/${id}`, { status });
      await this.getData();

      this.isLoading = false;
    }
  },

  metaInfo() {
    return {
      title: this.$t("manager.orders.in_delivery.title")
    };
  }
};
</script>
