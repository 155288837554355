<template>
  <CDataTable
    :loading="isLoading"
    :items="items"
    :fields="fields"
    :noItemsView="{
      noResults: $t('admin.no_results'),
      noItems: $t('admin.no_items')
    }"
    border
    striped
    hover
    pagination
  >
    <template #order_id="{item}">
      <td class="py-2">
        <OrderIdComposition :item="item" />
      </td>
    </template>

    <template #show_user="{item}">
      <td class="py-2">
        {{ item.user.full_name }}
      </td>
    </template>
  </CDataTable>
</template>

<script>
import OrderIdComposition from "@/components/manager/OrderIdComposition";

export default {
  components: {
    OrderIdComposition
  },

  data() {
    return {
      isLoading: true,
      items: [],
      fields: [
        {
          key: "order_id",
          label: this.$t("manager.orders.order_id"),
          _style: "width: 100px"
        },
        {
          key: "restaurant",
          label: this.$t("manager.orders.restaurant")
        },
        {
          key: "show_user",
          label: this.$t("manager.orders.client")
        },
        {
          key: "delivery_time",
          label: this.$t("manager.orders.delivery_time")
        },
        {
          key: "status",
          label: this.$t("manager.orders.status")
        }
      ]
    };
  },

  methods: {
    getData() {
      return this.$http
        .get(`/api/manager/sub-orders`, { params: { is_pickup: true } })
        .then(response => {
          this.items = response.data.data;
          this.isLoading = false;
        });
    }
  },

  metaInfo() {
    return {
      title: this.$t("manager.orders.pickup.title")
    };
  }
};
</script>
